import serverRequestConfiguration from "./serverRequestConfiguration";
import { httpResourceHelper } from "./httpResourceHelper";
import filesystemDomain from "../reducers/fileSystemDomain";
// this needs to deal with missing features so validate against the supported features
// const supportedFeatures = ['USE_EVENTS', 'USE_ASSOCIATIONS', 'USE_SOURCES', 'USE_SITES', 'USE_SHAPES']

const requestErrorMessage = (url, domain, error) =>
  `The '${domain}' returned ${error.message}`;

const initialDomain = {
  events: [],
  sources: [],
  categories: [],
  filter: [],
  narrative: [],
  notifications: [],
  shapes: [],
  sites: [],
};

const fetchDomainNew = (features) => {
  const isServerData = process.env.REACT_APP_IS_SERVER_DATA === "true";

  const defaultDomain = isServerData ? initialDomain : filesystemDomain;

  const { USE_EVENTS, USE_SOURCES, USE_CATEGORIES, USE_FILTERS, USE_NARRATIVES, USE_SITES, USE_SHAPES } =
    features;

  const activeFeatures = [
    "USE_EVENTS",
    "USE_SOURCES",
    "USE_CATEGORIES",
    "USE_FILTERS",
    "USE_NARRATIVES",

    // "USE_SITES",
    // "USE_SHAPES",
  ];

  // test this
  // const { configuration, errors } = serverRequestConfiguration('NO_ARRAY')
  const { configuration, errors } = serverRequestConfiguration(activeFeatures);
  const { SOURCES_URL, CATEGORIES_URL, NARRATIVES_URL, FILTERS_URL, SHAPES_URL, SITES_URL, EVENTS_URL } =
    configuration;

  const notifications = [...errors];
  // if there are errors don't go any further
  const handleError = (message) => {
    notifications.push({
      message,
      type: "error",
    });
  };

  const fetchDomainHttpResource = (url, domain) =>
    httpResourceHelper(
      url,
      (error) => handleError(requestErrorMessage(url, domain, error)),
      []
    );

  return () => {
    // const EVENT_DATA_URL = [
    //   "http://localhost:4040/api/curfew/export_events/deeprows",
    // ];
    // // everything should be a list
    // events are mandatory?
    // let eventsPromise = Promise.resolve(defaultDomain.events)
    // const eventsPromise = Promise.all(
    //   EVENT_DATA_URL.map((url) => fetchDomainHttpResource(url, "EVENTS_URL"))
    // ).then((results) => results.flatMap((event) => event));

    let eventsPromise = Promise.resolve(defaultDomain.events);
    if (isServerData && USE_EVENTS) {
      if (EVENTS_URL) {
        eventsPromise = fetchDomainHttpResource(EVENTS_URL, "EVENTS_URL");
      }
    }

    let categoriesPromise = Promise.resolve(defaultDomain.categories);
    if (isServerData && USE_CATEGORIES) {
      if (CATEGORIES_URL) {
        categoriesPromise = fetchDomainHttpResource(
          CATEGORIES_URL,
          "CATEGORIES_URL"
        );
      }
    }

    let narrativesPromise = Promise.resolve(defaultDomain.narratives);
    if (isServerData && USE_NARRATIVES) {
      if (NARRATIVES_URL) {
        narrativesPromise = fetchDomainHttpResource(
          NARRATIVES_URL,
          "NARRATIVES_URL"
        );
      }
    }


    let filtersPromise = Promise.resolve(defaultDomain.filters);
    if (isServerData && USE_FILTERS) {
      if (FILTERS_URL) {
        filtersPromise = fetchDomainHttpResource(
          FILTERS_URL,
          "FILTERS_URL"
        );
      }
    }


    let sourcesPromise = Promise.resolve(defaultDomain.sources);
    if (isServerData && USE_SOURCES) {
      if (SOURCES_URL) {
        sourcesPromise = fetchDomainHttpResource(
          SOURCES_URL, 
          "SOURCES_URL");
      }
    }

    let sitesPromise = Promise.resolve(defaultDomain.sites);
    if (isServerData && USE_SITES) {
      if (SITES_URL) {
        sitesPromise = fetchDomainHttpResource(SITES_URL, "SITES_URL");
      }
    }

    let shapesPromise = Promise.resolve(defaultDomain.shapes);
    if (isServerData && USE_SHAPES) {
      if (SHAPES_URL) {
        shapesPromise = fetchDomainHttpResource(SHAPES_URL, "SHAPES_URL");
      }
    }

    return Promise.all([
      eventsPromise,
      categoriesPromise,
      narrativesPromise,
      filtersPromise,
      sourcesPromise,
      sitesPromise,
      shapesPromise,
    ])
      .then((response) => {
        // no magic numbers
        const result = {
          events: response[0],
          categories: response[1],
          narratives: response[2],
          filters: response[3],
          sources: response[4],
          sites: response[5],
          shapes: response[6],
          notifications,
        };
        if (notifications.length > 0) {
          const errorList = notifications.map(
            (error) => `\n\n${error.message}`
          );
          throw new Error(
            `Some URLs returned negative. If you are in development, check the server is running ${errorList}`
          );
        }
        return result;
      })
      .catch((error) => {
        console.log("error", error.message);
        // TODO: handle this appropriately in React hierarchy
        // rather than alert would a panel not be better
        // alert(error.message)
      });
  };
};

export default fetchDomainNew;
