import sources from "../assets/live-data/sources.json"
import events from "../assets/live-data/events.json"
import categories from "../assets/live-data/categories.json"
import narratives from "../assets/live-data/narratives.json"
import filters from "../assets/live-data/filters.json"

const domain = {
  events,
  sources,
  categories,
  narratives,
  filters
}

export default domain
